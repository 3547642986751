// eslint-disable-next-line import/prefer-default-export
export const listVariants = Object.freeze({
  visible: (i) => ({
    opacity: 1,
    transition: {
      delay: i * 0.2,
    },
  }),
  hidden: { opacity: 0 },
})

export default Object.freeze([
  {
    icon: '💻',
    text: 'works as a software engineer',
  },
  {
    icon: '🇮🇳',
    text: 'from India, and can read and write 3 different languages',
  },
  {
    icon: '🎒',
    text: 'love to bagpack to different places',
  },
  {
    icon: '👻',
    text: 'omnivert in nature',
  },
  {
    icon: '✍',
    text: 'write about life',
  },
  {
    icon: '💪',
    text: "non-smoking teetotaller. don't need tea, coffee or beer to work for hours",
  },
  {
    icon: '🎹',
    text: 'can play piano',
  },
  {
    icon: '🏊',
    text: 'swimming relaxes me',
  },
  {
    icon: '🎧',
    text: 'music helps me to disconnect from the world',
  },
  {
    icon: '🌱',
    text: 'vegetarian',
  },
])
